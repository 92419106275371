:root {
  /* Light mode colors */
  --light-black: #0d1321;
  --light-trans-black: #0d132155;
  --light-white: #f8f1ff;
  --light-trans-white: #f8f1ff55;
  --light-primary: #ed40a9;
  --light-accent: #0094c6;
  --light-grey: #8f98a3;

  /* Dark Mode Colors */
  --dark-black: #0e1116;
  --dark-trans-black: #0e111655;
  --dark-white: #ffeddf;
  --dark-trans-white: #ffeddf55;
  --dark-primary: #cd1383;
  --dark-accent: #007aa3;
  --dark-grey: #76818e;

  /* Set light mode colors */
  --black: var(--light-black);
  --trans-black: var(--light-trans-black);
  --trans-white: var(--light-trans-white);
  --white: var(--light-white);
  --primary: var(--light-primary);
  --accent: var(--light-accent);
  --grey: var(--light-grey);

  /* Font faces */
  --sans-serif: "Pragati Narrow", sans-serif;
  --serif: "Baskerville Handcut", "Georgia";
  --monospace: monospace;

  /* Container widths */
  --max-container-width: 72rem;

  /* Line heights */
  --heading-line-height: 1.1545;
  --p-line-height: 1.4635;

  /* H1 text sizes */
  --h1-min-size: 2.6657405rem;
  --h1-flex-size: 5.331481vmax;
  --h1-max-size: 4.665045875rem;

  /* H2 text sizes */
  --h2-min-size: 2rem;
  --h2-flex-size: 4vmax;
  --h2-max-size: 3.5rem;

  /* H3 text sizes */
  --h3-min-size: 1.7323516674rem;
  --h3-flex-size: 3.4647033348vmax;
  --h3-max-size: 3.031615418rem;

  /* H4 text sizes */
  --h4-min-size: 1.5005211498rem;
  --h4-flex-size: 3.0010422995vmax;
  --h4-max-size: 2.6259120121rem;

  /* First paragraph text sizes */
  --p1-min-size: 1.2997151578rem;
  --p1-flex-size: 2.5994303158vmax;
  --p1-max-size: 2.2745015262rem;

  /* Paragraph text sizes */
  --p-min-size: 1.1545rem;
  --p-flex-size: 2.020375vmax;
  --p-max-size: 1.5874375rem;

  /* Tiny text sizes */
  /* Paragraph text sizes */
  --tiny-min-size: 0.7502605749rem;
  --tiny-flex-size: 1.312956006vmax;
  --tiny-max-size: 1.0316082905rem;

  /* Media query breakpoints */
  --tiny: 32rem;
  --medium: 40rem;
  --large: 60rem;
  --xlarge: 80rem;
}

@media screen and (prefers-color-scheme: dark) {
  /* Set dark mode colors */
  :root {
    --black: var(--dark-black);
    --trans-black: var(--dark-trans-black);
    --white: var(--dark-white);
    --trans-white: var(--dark-trans-white);
    --primary: var(--dark-primary);
    --accent: var(--dark-accent);
    --grey: var(--dark-grey);
  }
}
