html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
u,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
tr,
th,
td,
article,
aside,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /* stylelint-disable */
  font: inherit;
  /* stylelint-ensable */
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-family: var(--serif);
  padding: 0;
}

ol,
ul {
  list-style: none;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
}

code {
  font-family: var(--monospace);
}

input,
button {
  -webkit-appearance: none;
}
