@keyframes waspbumble {
  0% {
    transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg) scaleX(1);
  }

  16% {
    transform: rotateY(10deg) rotateX(10deg) rotateZ(0.1deg) scaleX(0.995);
  }

  33% {
    transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg);
  }

  50% {
    transform: rotateY(-10deg) rotateX(-10deg) rotateZ(-0.1deg) scaleX(1.005);
  }

  66% {
    transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg) scaleX(1);
  }

  83% {
    transform: rotateY(10deg) rotateX(10deg) rotateZ(0.1deg) scaleX(0.995);
  }

  92% {
    transform: rotateY(-10deg) rotateX(-10deg) rotateZ(-0.1deg) scaleX(1.005);
  }

  100% {
    transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg) scaleX(1);
  }
}

.rule.observed .silhouette {
  animation: 1333ms ease-in-out 0s 3 alternate waspbumble;
}
