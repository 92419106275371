/* Import basics */
@import "./vars.css";
@import "./fonts.css";
@import "./reset.css";

/* Begin Global Styles */

a {
  color: var(--accent);
}

a:hover {
  color: var(--primary);
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  text-transform: uppercase;
  font-family: var(--sans-serif);
  line-height: var(--heading-line-height);
}

h1 {
  font-size: clamp(var(--h1-min-size), var(--h1-flex-size), var(--h1-max-size));
}

h2 {
  font-size: clamp(var(--h2-min-size), var(--h2-flex-size), var(--h2-max-size));
}

h3 {
  font-size: clamp(var(--h3-min-size), var(--h3-flex-size), var(--h3-max-size));
}

h4 {
  font-size: clamp(var(--h4-min-size), var(--h4-flex-size), var(--h4-max-size));
}

p {
  color: var(--black);
  line-height: var(--p-line-height);
}

.backdrop {
  background-color: var(--grey);
}

@media screen and (min-width: 72rem) {
  .backdrop {
    background-image: paint(chemistreak);

    --chemistreak-stroke-color: var(--trans-white);
    --chemistreak-fill-color: var(--trans-white);
  }
}

.main {
  max-width: var(--max-container-width);
  margin: 0 auto;
  padding: 0 clamp(1rem, 1.5vmax, 2rem);
  background: var(--white);
  box-shadow: 0 0 1rem var(--trans-black);
}

/* End Global Styles */

/* Begin Banner Styles */

.banner {
  background: var(--black);
  margin: 0 0 clamp(1rem, 1.5vmax, 2rem);
  padding: clamp(1rem, 1.5vmax, 2rem);
}

.banner__copy {
  text-align: center;
  color: var(--white);
  font-size: clamp(var(--p-min-size), var(--p-flex-size), var(--p-max-size));
}

.banner__copy a {
  color: var(--white);
}

/* End Banner Styles */

/* Begin Header styles */

.header {
  background: var(--primary);
  text-align: center;
  margin: 0;
  padding: clamp(1rem, 1.5vmax, 2rem);
}

.header__book-number {
  display: block;
  margin: 0 auto 1rem;
}

.header__book-title,
.header__book-author {
  color: var(--white);
}

.header__book-title {
  margin: 0 0 0.1666666667ch;
}

.header__book-author {
  font-size: clamp(var(--p-min-size), var(--p-flex-size), var(--p-max-size));
  font-style: italic;
  margin: 0 0 2ch;
}

.header__book-author a,
.header__book-author a:hover {
  color: var(--white);
}

.order__cta {
  padding: clamp(1rem, 1.5vmax, 2rem);
  text-transform: uppercase;
  font-size: clamp(var(--p1-min-size), var(--p1-flex-size), var(--p1-max-size));
  font-family: var(--sans-serif);
  display: inline-block;
  text-decoration: none;
  background: var(--white);
  color: var(--primary);
}

/* End Header styles */

/* Begin Wedge Styles */

.wedge {
  width: 100%;
  display: block;
  margin: 0 0 2ch;
}

.header-wedge .wedge__shape {
  fill: var(--primary);
}

.testimonials-wedge .wedge__shape {
  fill: var(--accent);
}

/* End Wedge Styles */

/* Begin Intro Section Styles */

.intro {
  padding: clamp(1rem, 1.5vmax, 2rem) 0;
  text-align: center;
}

.intro p {
  width: 91.6666666667%;
  margin: 0 auto 2ch;
  font-size: clamp(var(--p-min-size), var(--p-flex-size), var(--p-max-size));
  text-align: left;
}

@media screen and (min-width: 32rem) {
  .intro p {
    width: 83.3333333333%;
  }
}

.intro p:first-of-type {
  font-size: clamp(var(--p1-min-size), var(--p1-flex-size), var(--p1-max-size));
  width: 100%;
  margin-bottom: 0;
  text-align: center;
}

@media screen and (min-width: 32rem) {
  .intro p:first-of-type {
    width: 91.6666666667%;
  }
}

.intro .rule {
  width: 100%;
  height: auto;
  display: block;
  margin: 2ch auto;
}

.intro .rule .silhouette,
.intro .rule .line {
  fill: var(--primary);
}

@media screen and (min-width: 32rem) {
  .intro .rule {
    margin: 3ch auto;
  }
}

/* End Intro Section Styles */

/* Begin Testimonials Section Styles */

.testimonials {
  background: var(--accent);
  padding: clamp(1rem, 1.5vmax, 2rem);
}

.testimonials__heading {
  margin: 0 0 0.3333333333ch;
  text-align: center;
}

.testimonials__heading,
.testimonial {
  color: var(--white);
}

.testimonial {
  line-height: var(--p-line-height);
  font-size: clamp(var(--p-min-size), var(--p-flex-size), var(--p-max-size));
  text-align: center;
}

.testimonial a,
.testimonial a:hover {
  color: var(--white);
}

.testimonials .rule {
  width: 100%;
  height: auto;
  margin: 2ch auto;
  display: block;
}

.testimonials .rule .silhouette,
.testimonials .rule .line {
  fill: var(--white);
}

.testimonials .rule:nth-of-type(2) {
  transform: rotateY(-180deg);
}

@media screen and (min-width: 32rem) {
  .testimonials .rule {
    margin: 3ch auto;
  }
}

.testimonial__source {
  display: block;
  font-size: clamp(var(--p-min-size), var(--p-flex-size), var(--p-max-size));
  margin: 1ch 0 0;
  font-style: italic;
  text-align: center;
}

.testimonial__source__avatar {
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  width: 2.75rem;
  height: 2.75rem;
  border: 0.125rem solid var(--white);
}

/* End Testimonials Section Styles */

@media screen and (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
    animation: none !important;
  }
}

.signup__status:target {
  display: block;
}

.signup__status:target + .signup__form {
  display: none;
}

.signup__form__label {
  margin: 0 0 1ch;
  font-weight: 700;
  color: var(--black);
  cursor: pointer;
}

.signup__form__fieldset {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.signup__form__input {
  border: 0.125rem solid var(--primary);
  padding: 0.25rem;
  border-radius: 0.375rem;
  margin: 0 0 1ch;
  width: 100%;
  max-width: 83.3333333333%;
  color: var(--black);
  text-align: center;
  background: var(--light-white);
}

@media screen and (min-width: 32rem) {
  .signup__form__input {
    max-width: 66.6666666667%;
  }
}

.signup__form__submit {
  border: 0.125rem solid var(--primary);
  color: var(--white);
  background-color: var(--primary);
  cursor: pointer;
  padding: 0.5rem 1.25rem;
  border-radius: 0.375rem;
  font-weight: 700;
}

.signup__form__input:focus,
.signup__form__submit:focus {
  outline: 0.25rem solid var(--primary);
}

/* Begin About Author Section Styles */

.author {
  background: var(--black);
  padding: clamp(1rem, 1.5vmax, 2rem);
  margin: 4ch 0 0;
}

.author a,
.author a:hover {
  color: var(--white);
}

.author__heading,
.author__bio {
  color: var(--white);
  text-align: center;
}

.author__heading {
  margin: 0 0 0.3333333333ch;
}

.author__avatar {
  display: block;
  margin: 2ch auto;
  border-radius: 50%;
  border: 0.25rem solid var(--white);
  min-width: 8rem;
  height: auto;
  width: 33.3333333333%;
  max-width: 12rem;
}

.author__bio {
  margin: 0 0 1ch;
  font-size: clamp(var(--p-min-size), var(--p-flex-size), var(--p-max-size));
}

.author .rule {
  width: 100%;
  height: auto;
  margin: 2ch auto;
  display: block;
  transform: rotateY(-180deg);
}

.author .rule .silhouette,
.author .rule .line {
  fill: var(--white);
}

@media screen and (min-width: 32rem) {
  .author .rule {
    margin: 3ch auto;
  }
}

/* End About Author Section Styles */

/* End Signup Form Styles */

.footer__colophon {
  color: var(--white);
  text-align: center;
  font-style: italic;
  font-size: clamp(var(--tiny-min-size), var(--tiny-flex-size), var(--tiny-max-size));
}

/* End Footer Styles */

/* Import animations */

@import "./animations.css";
